import React from 'react'
import logo from './logo.png'
import css from './index.module.css'

const Logo = () => {
  return (
    <div className={css.component_logo}>
      <img src={logo}/>
      <span className={css.header}>Lux-cash.ru</span>
    </div>

  )
}

export default Logo
